import 'bootstrap';

import './css/styles.scss';

import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons';

library.add(faEnvelope, faLinkedinIn)
dom.watch();
